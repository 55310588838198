import { ToastContainer as ToastContainerBase } from 'react-toastify';

const TOAST_LIMIT = 4;
const TOAST_AUTO_CLOSE = 30000;

const ToastContainer = ({ isSoftphone, noSidebar }: { isSoftphone: boolean; noSidebar?: true }) => (
  <ToastContainerBase
    draggable={false}
    // We have request ids in toasts, so they need to be able to click/highlight
    closeOnClick={false}
    theme="colored"
    position="bottom-left"
    limit={TOAST_LIMIT}
    className={isSoftphone ? 'Toastify__toast-container--softphone' : undefined}
    toastClassName={noSidebar ? 'Toastify__toast--no-sidebar' : undefined}
    autoClose={TOAST_AUTO_CLOSE}
  />
);

export default ToastContainer;
