import { TAttachment } from 'lib/common/types/email/TAttachment';

// Attachment content IDs are surrounded by angle brackets in the attachment objects, this regex matches them
const ATTACHMENT_CID_REGEX = /[<>]/g;

type TContentId = Partial<TAttachment>;

export default function getAttachmentCid(attachment?: TContentId) {
  return attachment?.contentId?.replaceAll(ATTACHMENT_CID_REGEX, '');
}
