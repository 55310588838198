import { Attachment } from 'postal-mime';

import { LogEvents, logger } from '../../../components/LoggerController';
import { TAttachment } from '../../../types/email/TAttachment';
import { getUnit8ArrayHash } from '../../../utils/getUnit8ArrayHash';
import workers from '../../../workers';

// The larger this number, the more memory used to potentially display images inline. Trade off between quality/closeness to the original and performance
const MAX_INLINE_IMAGE_SIZE_PX = 1000;

const BROKEN_IMAGE_BASE_64 = ' ';

// Converts inline images into more reasonable sizes for the implementation and filters them out of the original email using a service worker
export const preprocessEmail = async (rawEmail: string) => {
  const parseEmailContentWorker = workers.parseEmailContent();
  const resizeImageWorker = workers.resizeImage();

  const email = await parseEmailContentWorker.run({ rawEmail });

  const attachments: TAttachment[] = await Promise.all(
    email.attachments.map(async (attachment: Attachment) => {
      const hash = await getUnit8ArrayHash('SHA-1', attachment.content);

      if (attachment.disposition !== 'inline') {
        return { ...attachment, hash };
      }

      try {
        const base64 = await resizeImageWorker.run({
          uint8Array: attachment.content,
          maxWidth: MAX_INLINE_IMAGE_SIZE_PX,
          maxHeight: MAX_INLINE_IMAGE_SIZE_PX
        });

        return { ...attachment, hash, base64 };
      } catch (error) {
        logger.error(LogEvents.EMAIL.IMAGE_RESIZE_FAILED, { error });

        // If the image fails to resize, we still want to display the email content, so we return a broken image base64
        return { ...attachment, hash, base64: BROKEN_IMAGE_BASE_64 };
      }
    })
  );

  return { ...email, attachments };
};
