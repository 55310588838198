import { ReactElement, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import _debounce from 'lodash.debounce';

import { Permissions } from '@cloud-wave/neon-common-lib';
import { translate } from '@cloud-wave/neon-common-lib/app';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useWrapUpCodeContext } from 'lib/common/contexts/WrapUpCodeContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useHasOutcomesAccess from 'lib/common/hooks/useHasOutcomesAccess';

import Accordion from 'lib/common/components/Accordion';
import HeadingLabel from 'lib/common/components/HeadingLabel';
import { Input } from 'lib/common/components/Input';

import WRAP_UP_CODE_TYPES from 'lib/common/constants/ACW/wrapUpCodeTypes';
import contactStates from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TaskContentTypes from 'lib/common/types/TaskContentTypes';
import isVoice from 'lib/common/utils/isVoice';

import ACWCodeSelector from '../ACWCodeSelector';
import AISummaryInput from './components/AISummaryInput';
import DISPLAY_LABEL from './constants/displayLabel';

import './acw-outcome.scss';

interface IACWOutcomeProps {
  withoutAccordion?: boolean;
  accordionDefaultOpen?: boolean;
  className?: string;
}

const UPDATE_TASK_CONTENT_DEBOUNCE_MS = 500;

export default function ACWOutcome({ withoutAccordion, accordionDefaultOpen, className }: IACWOutcomeProps) {
  const { isSoftphone } = useLayout();
  const { hasPermission } = usePermissionsContext();

  const {
    actions: { setTaskContent },
    state: { tasks, selectedTaskId }
  } = useContactContext();

  const {
    state: { queueWrapUpCodes, loaded },
    actions: { fetchWrapUpCodes }
  } = useWrapUpCodeContext();

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  const isInACW = selectedTask?.status === contactStates.ACW;

  const [notes, setNotes] = useState(selectedTask?.ACW?.notes || '');

  const canAgentAccessOutcomes = useHasOutcomesAccess(selectedTask);

  const canAgentSkipACW = !hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW_CANT_SKIP
  });

  const notesAreRequired = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW_NOTES_REQUIRED
  });

  const canGenerateChatAISummary =
    hasPermission({
      type: 'tenant',
      permission: Permissions.AGENT_INTERACTION_CHAT_ANALYSIS
    }) && selectedTask?.type === CONTACT_TYPES.CHAT;

  const canGenerateVoiceAISummary =
    hasPermission({
      type: 'tenant',
      permission: Permissions.AGENT_INTERACTION_VOICE_ANALYSIS
    }) && isVoice(selectedTask);

  const canGenerateAISummary = canGenerateChatAISummary || canGenerateVoiceAISummary;
  // Ignore all groups and check if there are any codes for the queue
  const hasQueueWrapUpCodes =
    loaded && queueWrapUpCodes.some((wrapUpCode) => wrapUpCode.type === WRAP_UP_CODE_TYPES.CODE);

  const handleUpdatedNotes = useMemo(
    () =>
      _debounce((notes: string) => {
        setTaskContent(selectedTaskId, TaskContentTypes.ACW, { ...selectedTask?.ACW, notes: notes || void 0 });
      }, UPDATE_TASK_CONTENT_DEBOUNCE_MS),
    [selectedTask?.ACW]
  );

  useEffect(() => {
    if (!canAgentAccessOutcomes) {
      return;
    }

    fetchWrapUpCodes();
  }, []);

  useEffect(() => {
    setNotes(selectedTask?.ACW?.notes || '');
  }, [selectedTask?.taskId]);

  if (!selectedTask || !canAgentAccessOutcomes) {
    return null;
  }

  const onNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);

    handleUpdatedNotes(event.target.value);
  };

  const wrapInputElement = ({
    title,
    status,
    component
  }: {
    title: string;
    status?: string;
    component: ReactElement;
  }) => {
    const headingLabelEl = <HeadingLabel primary={title} secondary={status} smallTitle noMargin />;
    const id = `outcome-${title.replaceAll(' ', '-').toLowerCase()}`;

    if (withoutAccordion) {
      return (
        <div data-testid={id} className="acw-outcome__item">
          {headingLabelEl}
          {component}
        </div>
      );
    }

    return (
      <Accordion
        ariaLabel={`${title} ${status || ''} accordion`}
        small
        title={headingLabelEl}
        className="acw-outcome__accordion"
        defaultOpen={accordionDefaultOpen}
      >
        {component}
      </Accordion>
    );
  };

  const wrapCodeStatus = canAgentSkipACW ? DISPLAY_LABEL.OPTIONAL : DISPLAY_LABEL.REQUIRED;
  const notesStatus =
    !canAgentSkipACW && !hasQueueWrapUpCodes && notesAreRequired ? DISPLAY_LABEL.REQUIRED : DISPLAY_LABEL.OPTIONAL;

  const notesInput =
    canGenerateAISummary && isInACW ? (
      <AISummaryInput selectedTask={selectedTask} />
    ) : (
      <Input
        className="acw-outcome__notes"
        multiline
        minRows={4}
        placeholder="More information about this interaction"
        maxRows={8}
        value={notes}
        onChange={onNotesChange}
      />
    );

  // We don't show optional/required unless the agent is in ACW, as it's always optional during an interaction
  return (
    <div className={cx('acw-outcome', { 'acw-outcome--softphone': isSoftphone, 'mb-20': !isSoftphone }, className)}>
      {wrapInputElement({
        title: 'Wrap Up Code',
        status: hasQueueWrapUpCodes && isInACW ? wrapCodeStatus : void 0,
        component: (
          <ACWCodeSelector
            selectedTask={selectedTask}
            queueWrapUpCodes={queueWrapUpCodes}
            loaded={loaded}
            className={isSoftphone ? 'mt-10' : void 0}
          />
        )
      })}
      {wrapInputElement({
        title: isSoftphone ? 'Notes' : 'Interaction Notes',
        status: isInACW ? notesStatus : void 0,
        component: notesInput
      })}
    </div>
  );
}
