import { useEffect, useState } from 'react';

import Button from 'lib/common/components/Button';

import styles from './playground.module.scss';

export default function Playground() {
  const [shouldError, setShouldError] = useState(false);

  useEffect(() => {
    if (shouldError) {
      throw new Error();
    }
  }, [shouldError]);

  return (
    <div className={styles['playground']}>
      <Button
        onClick={() => {
          setShouldError(true);
        }}
      >
        Crash NEONNOW
      </Button>
    </div>
  );
}
