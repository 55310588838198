import { useEffect, useState } from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';

import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';

import ClickableIcon from 'lib/common/components/ClickableIcon';

const DEFAULT_ICON_SIZE = 15;
const SUCCESS_TIMEOUT_MS = 1000;

export default function CopyToClipboardButton({
  text,
  iconSize = DEFAULT_ICON_SIZE
}: {
  text?: string;
  iconSize?: number;
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) {
      return;
    }

    // set a timeout to show the success icon for one second
    const timeout = setTimeout(() => {
      setCopied(false);
    }, SUCCESS_TIMEOUT_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <ReactCopyToClipboard text={text || ''} onCopy={() => setCopied(true)}>
      <ClickableIcon
        tooltip="Copy Text"
        icon={copied ? faCheck : faCopy}
        size={iconSize}
        className="ml-5"
        disabled={copied || !text}
      />
    </ReactCopyToClipboard>
  );
}
