import { Auth } from 'aws-amplify';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import { CURRENT_USER_FULLNAME_KEY, REFRESH_TOKEN_EXPIRY_STORAGE_KEY } from '../constants';

export const TOKEN_REVOKED_ERROR = 'Access Token has been revoked';

export const handleCognitoSignout = async ({ global }: { global?: boolean }) => {
  try {
    sessionStorage.removeItem(CURRENT_USER_FULLNAME_KEY);
    localStorage.removeItem(REFRESH_TOKEN_EXPIRY_STORAGE_KEY);

    await Auth.signOut({ global });
  } catch (error: any) {
    // Fixes NEON-3820 - https://github.com/aws-amplify/amplify-js/issues/3540#issuecomment-506442759
    if (error?.message === TOKEN_REVOKED_ERROR) {
      return await handleCognitoSignout({ global: false });
    }

    logger.error(LogEvents.AUTH.SIGN_OUT.COMPLETED.FAIL, { error });
    throw error;
  }
};
