import { useEffect, useRef, useState } from 'react';

import { Auth } from 'aws-amplify';

import { useConfigContext } from 'lib/core/config';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import type TUser from 'lib/common/types/User';

import { CURRENT_USER_FULLNAME_KEY } from '../constants';
import { AuthStages } from '../types/AuthState';

const USERNAME_SUFFIX = '@neon.com';

type Fetch = (url: string, options?: RequestInit) => Promise<Response>;

export const useInitializeUser = ({
  authStage,
  fetch_,
  setError
}: {
  authStage: keyof typeof AuthStages;
  isIsolatedMode: boolean;
  fetch_: Fetch;
  setError: (error: boolean) => void;
  signOut: (signOutType: string) => void;
}) => {
  const { config } = useConfigContext();
  const [user, setUser] = useState<TUser | null>(null);

  // Allows callbacks to get the latest user to avoid stale references, eg. in connect handlers
  const userRef = useRef(user);
  const getUser = () => userRef?.current;

  const initializeUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();

    // tenantId__username (non sso) || tenantId__username@company.com (sso)
    const usernameWithTenantId = currentUser.username.includes(USERNAME_SUFFIX)
      ? currentUser.username.split('@')[0]
      : currentUser.username;

    const userId = usernameWithTenantId.split('__')[1];

    const objectKey = `${config.TENANT_ID}__${userId}`;
    const agentUrl = `${config.AGENT_SERVICE_URL}/agent/${objectKey}`;

    try {
      const response = await fetch_(agentUrl);
      const data = await response.json();

      const userToStore = { ...data, username: userId };

      setUser(userToStore);

      sessionStorage.setItem('email', userToStore.email || '');
      sessionStorage.setItem(CURRENT_USER_FULLNAME_KEY, `${userToStore.firstName} ${userToStore.lastName}`);
      logger.info(LogEvents.AUTH.INITIALIZE_USER.SUCCESS);
    } catch (error) {
      logger.info(LogEvents.AUTH.INITIALIZE_USER.FAIL, { error });
      setError(true);
    }
  };

  useEffect(() => {
    if (authStage.includes('complete')) {
      initializeUser();
    }
  }, [authStage]);

  useEffect(() => {
    userRef.current = user;
  }, [user, authStage]);

  return { user, getUser };
};
