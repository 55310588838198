import IEmailMetadata from 'lib/common/types/email/EmailMetadata';

import { preprocessEmail } from './preprocessEmail';

export default async function getEmailContent({ fetch_, config, id }) {
  const res = await fetch_(`${config.EMAIL_URL}/user/${config.TENANT_ID}/email/${id}`);

  const { data } = await res.json();

  const rawEmail = await (await fetch(data.emailURL)).text();
  const email = await preprocessEmail(rawEmail);

  return { email, metadata: data.metadata as IEmailMetadata };
}
