import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import InfoCard from './InfoCard';
import { InfoCardGroupGap, InfoCardSize, InfoCardType, TInfoCardGroupGap, TInfoCardSize, TInfoCardType } from './types';

import styles from './info-card.module.scss';

type InfoCardGroupProps = {
  groupType?: TInfoCardType;
  groupSize?: TInfoCardSize;
  small?: boolean;
  minimal?: boolean;
  gap?: TInfoCardGroupGap;
  alternating?: boolean;
  className?: string;
  cards: {
    type?: TInfoCardType;
    size?: TInfoCardSize;
    className?: string;
    title?: string;
    icon: IconDefinition;
    text: ReactNode;
    smallIcon?: IconDefinition;
    callToAction?: {
      text: string;
      onClick: () => void | Promise<void>;
    };
  }[];
};

const InfoCardGroup = ({
  groupType = InfoCardType.PRIMARY,
  groupSize = InfoCardSize.LARGE,
  small = false,
  gap = InfoCardGroupGap.MEDIUM,
  alternating = false,
  className,
  cards,
  minimal
}: InfoCardGroupProps) => (
  <div
    className={cx(styles['info-card-group'], { [styles['info-card-group--alternating']]: alternating }, className)}
    style={{ gap }}
  >
    {...cards.map(({ type, size, className, title, icon, text, smallIcon, callToAction }, index) => (
      <InfoCard
        key={index}
        className={className}
        title={title}
        icon={icon}
        text={text}
        type={type ?? groupType}
        size={size ?? groupSize}
        small={small}
        smallIcon={smallIcon}
        minimal={minimal}
        callToAction={callToAction}
      />
    ))}
  </div>
);

export default InfoCardGroup;
