import { ReactNode } from 'react';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import ToastContainer from 'lib/common/components/ToastContainer';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';

import Context from './Context';
import Layout from './components/Layout';

const LayoutProvider = ({ children, isForcedSoftphone }: { children: ReactNode; isForcedSoftphone: boolean }) => {
  const isSoftphone = useIsSoftphoneQuery();
  const isMediumScreen = useIsMediumScreen();

  return (
    <Context.Provider value={{ isSoftphone: isForcedSoftphone || isSoftphone, isMediumScreen }}>
      <Layout isSoftphone={isSoftphone} isForcedSoftphone={isForcedSoftphone}>
        {children}
      </Layout>
      <ToastContainer isSoftphone={isSoftphone} />
    </Context.Provider>
  );
};

export default LayoutProvider;
