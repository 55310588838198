import { useEffect, useState } from 'react';

import { useConfigContext } from 'lib/core/config';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import SIGN_OUT_EVENT from 'lib/common/constants/signOutEvent';

import type TUser from 'lib/common/types/User';

import SIGN_OUT_TYPES from '../constants/signOutTypes';

type Fetch = (url: string, options?: RequestInit) => Promise<Response>;

export const useInitializeApp = ({
  connectUserId,
  fetch_,
  setEmail,
  setError,
  signOut,
  user,
  removeStorageItem
}: {
  connectUserId: string;
  fetch_: Fetch;
  setEmail: (email: string) => void;
  setError: (error: boolean) => void;
  signOut: (signOutType: string) => void;
  user: TUser | null;
  removeStorageItem: (key: string) => void;
}) => {
  const [isAppInitialized, setIsAppInitialized] = useState<boolean>(false);
  const { config } = useConfigContext();
  const tenantId = config.TENANT_ID;

  const initializeApp = async () => {
    // If the user has previously signed out and this event exists in storage, remove it on sign in
    removeStorageItem(SIGN_OUT_EVENT);

    try {
      const userInfoJson = await fetch_(
        `${config.AGENT_SERVICE_URL}/connect/${tenantId}/describe/user/?objectId=${connectUserId}`
      );
      const userInfo = await userInfoJson.json();

      setEmail(userInfo.User.IdentityInfo.Email);

      // Logging users out after session invalid
      // @ts-expect-error This exists and works but isn't in the type
      connect.core.getEventBus().subscribe(connect.EventType.AUTH_FAIL, () => {
        logger.warn(LogEvents.AUTH.SESSION_EXPIRED, { user: user?.email });

        signOut(SIGN_OUT_TYPES.AUTH_FAIL);
      });

      setIsAppInitialized(true);

      logger.info(LogEvents.AUTH.INITIALIZE_APP.SUCCESS);
    } catch (error) {
      logger.info(LogEvents.AUTH.INITIALIZE_APP.FAIL, { error });
      setError(true);
    }
  };

  useEffect(() => {
    if (!user || !connectUserId || isAppInitialized) {
      return;
    }

    initializeApp();
  }, [user, connectUserId]);

  return { isAppInitialized };
};
