import sizeMe from 'react-sizeme';

import cx from 'classnames';
import { format } from 'date-fns';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Accordion from 'lib/common/components/Accordion';
import Text from 'lib/common/components/Text';

import formatDuration from './utils/formatDuration';

import styles from './acw-summary.module.scss';

const SUMMARY_COLUMN_MIN_WIDTH = 300;

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const ACWSummary = ({
  taskTimeInfo,
  size: { width = 0 } = {},
  accordionDefaultOpen = false,
  withoutAccordion = false
}: {
  taskTimeInfo: { startedAt: Date; endedAt: Date; activeDuration?: number };
  size: { width?: number; height?: number };
  accordionDefaultOpen?: boolean;
  withoutAccordion?: boolean;
}) => {
  const { isSoftphone } = useLayout();
  const { startedAt, endedAt, activeDuration } = taskTimeInfo;
  const isSmallColumn = width < SUMMARY_COLUMN_MIN_WIDTH && !isSoftphone;
  const activeDurationFormatted = formatDuration(activeDuration);

  const hrEl = (
    <hr
      className={cx(styles['acw-summary__item__vertical-line'], {
        [styles['acw-summary__item__vertical-line--softphone']]: isSoftphone
      })}
      data-testid="acw-summary-hr"
    />
  );

  const titleEl = (title) => (
    <Text color={!isSmallColumn ? 'darkGrey' : void 0} type={isSoftphone ? 'extraSmall' : void 0}>
      {title}
    </Text>
  );

  const formattedDateEl = (date) => <Text>{format(date, 'h:mm aa')}</Text>;

  const summaryEl = (
    <div
      className={cx(styles['acw-summary'], {
        [styles['acw-summary--small-column']]: isSmallColumn
      })}
    >
      <div
        className={cx(styles['acw-summary__item'], {
          [styles['acw-summary__item--small-column']]: isSmallColumn
        })}
      >
        {titleEl('Started')}
        {formattedDateEl(startedAt)}
      </div>
      {!isSmallColumn && hrEl}
      <div
        className={cx(styles['acw-summary__item'], {
          [styles['acw-summary__item--small-column']]: isSmallColumn
        })}
      >
        {titleEl('Ended')}
        {formattedDateEl(endedAt)}
      </div>
      {activeDuration && (
        <>
          {!isSmallColumn && hrEl}
          <div
            className={cx(styles['acw-summary__item'], {
              [styles['acw-summary__item--small-column']]: isSmallColumn
            })}
          >
            {titleEl('Duration')}
            <Text>{activeDurationFormatted}</Text>
          </div>
        </>
      )}
    </div>
  );

  if (withoutAccordion) {
    return summaryEl;
  }

  return (
    <Accordion className="mt-10" ariaLabel="Summary accordion" small title="Summary" defaultOpen={accordionDefaultOpen}>
      {summaryEl}
    </Accordion>
  );
};

export default sizeMeHOC(ACWSummary);
