export default function formatTranscriptTime(ms) {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const secondsToDisplay = seconds % 60;
  const minutesToDisplay = minutes % 60;
  const hoursToDisplay = hours % 24;
  const showHours = hoursToDisplay > 0;

  return [
    hoursToDisplay > 9 ? `${hoursToDisplay}` : showHours ? `0${hoursToDisplay}` : '',
    minutesToDisplay > 9 ? `${minutesToDisplay}` : `0${minutesToDisplay}`,
    secondsToDisplay > 9 ? `${secondsToDisplay}` : `0${secondsToDisplay}`
  ]
    .filter(Boolean)
    .join(':');
}
