import { translate } from '@cloud-wave/neon-common-lib/app';

import { IFetch } from 'lib/core/context/AuthProvider/Context';

import chatEvents from 'lib/common/constants/chatEvents';

import TChatTask from 'lib/common/types/ChatTask';
import { TConfig } from 'lib/common/types/Config';

import generateNotesAISummary from '../api/generateNotesAISummary';

export const generateChatAISummary = async ({
  selectedTask: chatTask,
  fetch_,
  config
}: {
  selectedTask: TChatTask;
  fetch_: IFetch;
  config: TConfig;
}) => {
  const joinEventMessageTime = chatTask.messages.find((message) => {
    return message?.Type === 'EVENT' && message?.ContentType === chatEvents.PARTICIPANT_JOINED;
  })?.AbsoluteTime;

  const normalisedMessages = chatTask.messages.reduce(
    (acc: string[], { ParticipantRole, Type, Content, AbsoluteTime }) => {
      if (
        ParticipantRole === 'SYSTEM' ||
        Type !== 'MESSAGE' ||
        // Check if message date is after joined event or if no joined event, then include all messages
        !(new Date(AbsoluteTime) > (joinEventMessageTime ? new Date(joinEventMessageTime) : new Date(0)))
      ) {
        return acc;
      }

      return [...acc, `${ParticipantRole}: ${Content}`];
    },
    []
  );

  const summary = await generateNotesAISummary({
    fetch_,
    config,
    prompt: `You are a bot for generating after chat work notes for a contact ${translate(
      'centre'
    )} interaction between an agent and a customer. Agent should be in first person, from their perspective. Do not use quotes. Summarise the following chat, using I instead of agent \n${JSON.stringify(
      normalisedMessages.join('\n')
    )}`
  });

  return summary;
};
