import { useEffect, useState } from 'react';

import { setContext, setUser as setSentryUser } from '@sentry/browser';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import connectGetter from 'lib/common/utils/connectGetter';

const getConnectUserID = (agent: connect.Agent) => {
  const agentQueues = connectGetter(agent, 'getConfiguration')?.routingProfile.queues;

  // By default, an agent is assigned a queue for transferring calls to the agent directly.
  const agentQueueArn = agentQueues?.find((q) => q.name === null)?.queueARN;

  // We get the connect user ID from the null queue ARN:
  // e.g. arn:aws:connect:ap-southeast-2:account:instance/instance-id/queue/agent/6ce04498-e7a3-44c2-a807-19a882bd6577"
  return agentQueueArn?.split('/')[4];
};

export const useConnectUser = ({
  loaded,
  signIn,
  setError
}: {
  loaded: boolean;
  signIn: (existingConnectUsername?: string) => void;
  setError: (error: boolean) => void;
}) => {
  const { agent, agentConfig } = useAgentContext();
  const [connectUserId, setConnectUserId] = useState<string | undefined>();

  useEffect(() => {
    if (!agent || loaded) {
      return;
    }

    if (!agentConfig) {
      logger.error(LogEvents.AUTH.AGENT_CONFIG.FAIL);
      setError(true);
      return;
    }

    setConnectUserId(getConnectUserID(agent));
    sessionStorage.setItem('c_user', agentConfig.username);

    signIn(agentConfig.username);

    // Set Sentry contexts for reporting
    setContext('user', agentConfig);
    setSentryUser({ username: agentConfig.username });
  }, [agent]);

  return { connectUserId, setConnectUserId };
};
