import { ReactNode, useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import { match } from 'ts-pattern';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import Loader from 'lib/common/components/Loader';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import connectGetter from 'lib/common/utils/connectGetter';

import { useStreamsContext } from '../StreamsProvider';
import Context from './Context';
import AuthScreen from './components/AuthScreen';
import LoginPage from './components/Login';
import SigningOutOverlay from './components/SigningOutOverlay';
import { useAmplify } from './hooks/useAmplify';
import { useAuthFlow } from './hooks/useAuthFlow';
import { useAuthSession } from './hooks/useAuthSession';
import { useConnectUser } from './hooks/useConnectUser';
import { useForgetDevice } from './hooks/useForgetDevice';
import { useInitializeApp } from './hooks/useInitializeApp';
import { useInitializeUser } from './hooks/useInitializeUser';

type Props = {
  children: ReactNode;
  isIsolatedMode?: boolean;
};

const AuthProvider = ({ children, isIsolatedMode }: Props) => {
  const { agent } = useAgentContext();
  const { failed: ccpFailed } = useStreamsContext();

  const [email, setEmail] = useState<string>('');

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const { removeStorageItem } = useLocalStorage();
  const { fetch_, signedOut, setSignedOut } = useAuthSession();

  const {
    authStage,
    invalidCode,
    mfaDestinations,
    mfaSelection,
    signingOutRef,
    signingOut,
    signIn,
    signOut,
    resendCode,
    restartAuthFlow,
    selectMfaDestination,
    provideMfaCode
  } = useAuthFlow({
    setError,
    email,
    fetch_,
    setSignedOut
  });

  const { connectUserId } = useConnectUser({ loaded, signIn, setError });

  const { isAmplifyConfigured } = useAmplify();

  const { deviceForgotten, forgetDevice } = useForgetDevice();

  const { user, getUser } = useInitializeUser({
    authStage,
    isIsolatedMode: !!isIsolatedMode,
    fetch_,
    setError,
    signOut
  });

  const { isAppInitialized } = useInitializeApp({
    connectUserId: connectUserId!,
    fetch_,
    setEmail,
    setError,
    signOut,
    user,
    removeStorageItem
  });

  useEffect(() => {
    if (isAppInitialized) {
      setLoaded(true);
    }
  }, [isAppInitialized]);

  useEffect(() => {
    if (isIsolatedMode) {
      setLoaded(true);
    }
  }, [authStage]);

  useEffect(() => {
    if (!isIsolatedMode) {
      return;
    }

    signIn();
  }, [isAmplifyConfigured]);

  useEffect(() => {
    setEmail(user?.email || '');
  }, [user, authStage]);

  useBeforeunload((event) => {
    const contacts = connectGetter(agent, 'getContacts') || [];

    // If there are no contacts, or we are signing out, don't block reload
    if (!contacts.length || signingOutRef.current) {
      return;
    }

    // Show dialog to prevent users from refreshing the page when they have tasks
    logger.warn(LogEvents.PAGE_RELOAD_WITH_TASKS, { contacts });
    event.preventDefault();
  });

  return (
    <Context.Provider
      value={{
        fetch_,
        loaded,
        email,
        signOut,
        connectUserId,
        user,
        getUser,
        forgetDevice
      }}
    >
      {signingOut && signingOutRef?.current && <SigningOutOverlay type={signingOutRef?.current} />}
      {match({ error, ccpFailed, signedOut, user: !!user, loaded, deviceForgotten })
        .with({ deviceForgotten: true }, () => <AuthScreen type="DEVICE_FORGOTTEN" />)
        .with({ signedOut: true }, () => <AuthScreen type="SIGNED_OUT" />)
        .with({ error: true }, { ccpFailed: true }, () => <AuthScreen type="AUTH_ERROR" />)
        .with({ user: false }, () => (
          <LoginPage
            authStage={authStage}
            mfaDestinations={mfaDestinations}
            mfaSelection={mfaSelection}
            invalidCode={invalidCode}
            selectMfaDestination={selectMfaDestination}
            provideMfaCode={provideMfaCode}
            resendCode={resendCode}
            restartAuthFlow={restartAuthFlow}
          />
        ))
        .with({ user: true, loaded: false }, () => <Loader />)
        .with({ loaded: true }, () => children)
        .exhaustive()}
    </Context.Provider>
  );
};

export default AuthProvider;
