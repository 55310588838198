import { useState } from 'react';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import SIGN_OUT_EVENT from 'lib/common/constants/signOutEvent';

import { handleCognitoSignout } from '../utils/handleCognitoSignout';

export const useForgetDevice = () => {
  const [deviceForgotten, setDeviceForgotten] = useState<boolean>(false);
  const { setStorageItem } = useLocalStorage();

  const forgetDevice = async () => {
    await handleCognitoSignout({ global: true });

    sessionStorage.clear();

    // Cross tab method to trigger sign out across other tabs in isolated mode
    // Other instances of neon will auto sign out because they have Connect embedded
    setStorageItem(SIGN_OUT_EVENT, 'true');

    setDeviceForgotten(true);

    logger.info(LogEvents.AUTH.FORGET_DEVICE.SUCCESS);
  };

  return { deviceForgotten, setDeviceForgotten, forgetDevice };
};
