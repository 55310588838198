import { useCallback, useEffect, useRef, useState } from 'react';

import { setContext } from '@sentry/browser';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { getDefaultBrandImageUrl, getFileUrl } from 'lib/core/utils';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import CONNECTION_TYPES from 'lib/common/constants/connectionType';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { ImageType } from 'lib/common/constants/imageBlockFields';

import requestNotificationPermission from 'lib/common/utils/requestNotificationPermission';

import { LogEvents, logger } from '../../components/LoggerController';
import Context from './Context';
import useAgentActions from './hooks/useAgentActions';
import useCallActions from './hooks/useCallActions';
import useChatActions from './hooks/useChatActions';
import useContactHandlers from './hooks/useContactHandlers';
import useInitialSetup from './hooks/useInitialSetup';
import useSetTaskContent from './hooks/useSetTaskContent';
import useTaskActions from './hooks/useTaskActions';
import useTaskRefresh from './hooks/useTaskRefresh';
import useTaskUtils from './hooks/useTaskUtils';
import { TAnyTask } from './types/ContactContext';
import TCustomerProfile from './types/CustomerProfile';
import { TTaskEvent } from './types/TaskEvent';
import { setDraftTasks } from './utils/draftTasksStorage';
import getUpdatedTasks from './utils/getUpdatedTasks';
import resolveCustomerProfile from './utils/resolveCustomerProfile';
import taskBuilder from './utils/taskBuilder';
import handleTaskEvent from './utils/taskEvent/handleTaskEvent';
import isOutdatedTaskEvent from './utils/taskEvent/isOutdatedTaskEvent';

function ContactProvider({ children }) {
  const { hasPermission } = usePermissionsContext();
  const { config } = useConfigContext();
  const { fetch_ } = useAuthContext();
  const icon =
    config && config.BRAND && config.BRAND.favicon && config.BRAND.favicon.fileKey
      ? getFileUrl(config.BRAND?.favicon.fileKey, config)
      : getDefaultBrandImageUrl(ImageType.FAVICON);
  const [tasks, setTasksState] = useState<TAnyTask[]>([]);
  const [selectedTaskId, setSelectedTaskIdState] = useState<string | undefined>();
  const [lastDialedNumber, setLastDialedNumber] = useState<string | undefined>('');
  const tasksEventRef = useRef<TTaskEvent[]>([]);

  // This ref is used to provide a static "latest" reference to the task list, mostly
  // for use in connect callbacks due to stale references. Never change this outside the setTasks function
  const taskListRef: { current: TAnyTask[] } = useRef(tasks);
  const activeTaskIdRef: { current?: string } = useRef(selectedTaskId);

  const setTasks = (tasks: TAnyTask[]) => {
    taskListRef.current = tasks;
    setTasksState(tasks);

    setContext('tasks', taskBuilder.reportableTasks(tasks));
  };

  const setSelectedTaskId = (selectedTaskId?: string) => {
    const selectedTask = getTasks().find((task) => task.taskId === selectedTaskId);

    if (selectedTaskId === activeTaskIdRef.current || (selectedTaskId && !selectedTask)) {
      return;
    }

    activeTaskIdRef.current = selectedTaskId;
    setSelectedTaskIdState(selectedTaskId);

    if (!selectedTask || selectedTask.type !== CONTACT_TYPES.CHAT) {
      return;
    }

    handleContactChange({ taskId: selectedTaskId, unreadMessage: false });
  };

  const getTasks = () => taskListRef.current;
  const getSelectedTaskId = () => activeTaskIdRef.current;

  const handleContactChange = useCallback(
    async (changes) => {
      tasksEventRef.current = handleTaskEvent({ changes, tasksEventRef });

      try {
        const newTasks = await getUpdatedTasks({
          ...changes,
          getTasks,
          getSelectedTaskId,
          handleContactChange,
          hasPermission,
          icon
        });

        const isOutdatedEvent = isOutdatedTaskEvent({ tasksEventRef, changes });

        if (isOutdatedEvent || !newTasks) {
          logger.info(LogEvents.TASK_EVENT.DROPPED, changes);
          return;
        }

        logger.info(LogEvents.TASK_EVENT.PROCESSED, changes);
        setTasks(newTasks);
      } catch {}
    },
    [tasks]
  );

  useEffect(() => {
    const selectedTask = getSelectedTask();

    if (selectedTask?.status === CONTACT_STATES.DRAFT) {
      return;
    }

    (window as any).getConnect().core.viewContact(selectedTask?.taskId);
  }, [selectedTaskId]);

  const hasCustomerProfilesPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.CUSTOMER_PROFILES
  });

  const hasMissedCallPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_MISSED_CALL
  });

  const setTaskContent = useSetTaskContent({ setTasks, getTasks });

  const { makeOutboundCall, onTransfer } = useAgentActions({ tasks, handleContactChange });
  const {
    rejectTask,
    acceptTask,
    endInitialConnection,
    removeTask,
    hangupAgent,
    onJoinConference,
    onHoldAll,
    onSwapConferenceCall,
    addDraftTask,
    removeDraftTask
  } = useTaskActions({ tasks, handleContactChange, setSelectedTaskId, setTasks });
  const {
    holdCall,
    resumeCall,
    onTransferToQueueOrAgent,
    holdConferenceConnection,
    resumeConferenceConnection,
    endConferenceConnection,
    muteConnection,
    unmuteConnection
  } = useCallActions({
    tasks,
    handleContactChange
  });
  const { sendMessage, sendTypingEvent, getChatTranscripts, sendAttachment, downloadAttachment } = useChatActions(
    tasks,
    handleContactChange,
    selectedTaskId
  );

  const searchForProfile = (contact) => {
    return resolveCustomerProfile({
      contact,
      handleContactChange,
      config,
      fetch_,
      hasCustomerProfilesPermission
    });
  };

  const {
    getActiveCallTask,
    getSelectedTask,
    onActiveCall,
    hasConnectingTask,
    hasConnectingInboundTask,
    hasConnectingInboundVoiceTask,
    hasConnectingVoiceTask,
    isCallInACW
  } = useTaskUtils(tasks, selectedTaskId);

  useInitialSetup({ setTasks, handleContactChange, getSelectedTaskId });

  const matchNewContact = async (contact: connect.Contact) => {
    await handleContactChange({ contact, status: CONTACT_STATES.CONNECTING });

    searchForProfile(contact);

    const newTask = getTasks().find((task) => task.taskId === contact.contactId);

    if (!newTask) {
      return;
    }

    const isOutbound = newTask.connectionType === CONNECTION_TYPES.OUTBOUND;

    logger.info(LogEvents.TASK[isOutbound ? 'OUTBOUND' : 'INCOMING'], { contactId: newTask.contact.contactId });

    if (document.hasFocus() || isOutbound) {
      return;
    }

    try {
      await requestNotificationPermission();

      new Notification(`Incoming ${newTask.type.toLowerCase()}`, {
        body: newTask.connectionValue,
        icon: `${window.location.origin}/android-chrome-192x192.png`
      });
    } catch {}
  };

  useContactHandlers({
    matchNewContact,
    handleContactChange,
    fetch_,
    config,
    hasMissedCallPermission,
    getTasks
  });

  useTaskRefresh({
    getTasks,
    handleContactChange,
    fetch_,
    config,
    hasMissedCallPermission
  });

  useEffect(() => {
    setDraftTasks(tasks);

    // This hook is run when the currently selected task is cleared, to select the first task in the list
    if (!selectedTaskId || tasks.some((task) => task.taskId === selectedTaskId)) {
      return;
    }

    setSelectedTaskId(tasks[0]?.taskId);
  }, [tasks]);

  const deleteExistingCustomerProfile = (taskId: string) =>
    handleContactChange({
      contact: tasks.find((task) => task.taskId === taskId)?.contact,
      profile: null
    });

  const matchExistingCustomerProfile = ({ profile, taskId }: { profile?: TCustomerProfile; taskId: string }) => {
    const contact = tasks.find((task) => task.taskId === taskId)?.contact;

    if (!contact) {
      return;
    }

    if (!profile) {
      return searchForProfile(contact);
    }

    return handleContactChange({ contact, profile });
  };

  return (
    <Context.Provider
      value={{
        actions: {
          setSelectedTaskId,
          matchExistingCustomerProfile,
          getActiveCallTask,
          deleteExistingCustomerProfile,
          setLastDialedNumber,
          setTaskContent,
          getSelectedTask,
          getSelectedTaskId,

          // agent actions
          makeOutboundCall,
          onTransfer,

          // task actions
          rejectTask,
          acceptTask,
          endInitialConnection,
          removeTask,
          hangupAgent,
          onJoinConference,
          onHoldAll,
          onSwapConferenceCall,
          addDraftTask,
          removeDraftTask,

          // call actions
          holdCall,
          resumeCall,
          onTransferToQueueOrAgent,
          holdConferenceConnection,
          resumeConferenceConnection,
          endConferenceConnection,
          muteConnection,
          unmuteConnection,

          // chat actions
          sendMessage,
          sendAttachment,
          downloadAttachment,
          sendTypingEvent,
          getChatTranscripts
        },
        state: {
          tasks,
          selectedTaskId,
          onActiveCall,
          lastDialedNumber,
          hasConnectingTask,
          hasConnectingInboundTask,
          hasConnectingInboundVoiceTask,
          hasConnectingVoiceTask,
          isCallInACW
        }
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default ContactProvider;
