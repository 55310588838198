import {
  faBox,
  faCirclePhone,
  faCircleQuestion,
  faCloudQuestion,
  faEnvelope,
  faMessageSmile
} from '@fortawesome/pro-duotone-svg-icons';
import { faBox as farBox, faCircleQuestion as farCircleQuestion } from '@fortawesome/pro-regular-svg-icons';

import { translate } from '@cloud-wave/neon-common-lib/app';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import IconConstellation from 'lib/common/components/IconConstellation';
import Text from 'lib/common/components/Text';

import { InfoCardGroup } from '../../InfoCard';

const Main = () => {
  const isSoftphone = useIsSoftphoneQuery();

  return (
    <>
      <div className="mb-40">
        <Text className="mb-5" type="heading1">
          Hello!
        </Text>
        <Text color="darkGrey">
          There’s a problem with the contact {translate('centre')} you’re trying to access. Here’s what you can do.
        </Text>
      </div>

      <InfoCardGroup
        minimal={isSoftphone}
        cards={[
          {
            title: "Can't Be Found",
            icon: isSoftphone ? farCircleQuestion : faCircleQuestion,
            text: (
              <Text>
                Double-check your contact {translate('centre')} URL. Make sure
                <Text.Bold inline> {window.location.hostname} </Text.Bold>
                is correct.
              </Text>
            )
          },
          {
            title: 'Archived or Unsubscribed',
            icon: isSoftphone ? farBox : faBox,
            text: `If you could previously sign in to this contact ${translate(
              'centre'
            )}, it might have been archived or the account may be closed. Please reach out to your Administrator for assistance.`
          }
        ]}
      />
    </>
  );
};

const Info = () => (
  <IconConstellation
    leftIcon={faCirclePhone}
    topIcon={faEnvelope}
    rightIcon={faMessageSmile}
    centerIcon={faCloudQuestion}
  />
);

export const NotFound = { Main, Info };
