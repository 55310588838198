/**
 * Provides a wrapper around native service workers, exposing a run method to send messages to the worker using promises.
 *
 * Add your new workers in the index file in /workers
 */

export default function createWorker<P, R>(worker: Worker) {
  return () => {
    // Maintains the promise for each message sent to the worker
    const promiseMap = new Map();

    // The service worker will call this callback with a returned value when computed
    worker.onmessage = (event) => {
      const { id, returnedValue, error } = event.data;

      const promise = promiseMap.get(id);

      if (!promise) {
        return;
      }

      promiseMap.delete(id);

      return returnedValue ? promise.resolve(returnedValue) : promise.reject(new Error(error));
    };

    return {
      run: (message: P): Promise<R> => {
        // Associate a unique id with each service worker call
        const id = crypto.randomUUID();

        return new Promise((resolve, reject) => {
          promiseMap.set(id, { resolve, reject });

          worker.postMessage({ ...message, id });
        });
      },

      // Usually there's no reason to terminate the worker, we want to keep them alive for a session duration
      terminate: () => worker.terminate()
    };
  };
}
