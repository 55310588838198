import { useState } from 'react';

import cx from 'classnames';
import { P, match } from 'ts-pattern';

import { useConfigContext } from 'lib/core/config';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Drawer from 'lib/common/components/Drawer';
import Modal from 'lib/common/components/Modal';
import SupportFooter from 'lib/common/components/SupportFooter';
import ToastContainer from 'lib/common/components/ToastContainer';
import ResponsiveLogo from 'lib/common/components/atoms/ResponsiveLogo';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';

import { AuthStage, MfaDestination, MfaDestinations } from '../../types/AuthState';
import ClearSessionSignOutButton from './components/ClearSessionSignOutButton';
import { LoginPages } from './pages';

import styles from './login.module.scss';

export default function Login({
  authStage,
  mfaDestinations,
  mfaSelection,
  invalidCode,

  selectMfaDestination,
  provideMfaCode,
  resendCode,
  restartAuthFlow
}: {
  authStage: AuthStage;
  mfaDestinations: { obfuscatedEmail: string | undefined; obfuscatedPhone: string | undefined } | null;
  mfaSelection: MfaDestination | undefined;
  invalidCode: boolean;

  selectMfaDestination: (dest: 'email' | 'sms') => Promise<void>;
  provideMfaCode: (code: string) => Promise<void>;
  resendCode: () => Promise<void>;
  restartAuthFlow: () => Promise<void>;
}) {
  const isSoftphone = useIsSoftphoneQuery();
  const isMediumScreen = useIsMediumScreen();
  const { config, notFound } = useConfigContext();

  const [open, setOpen] = useState(false);
  const [mfaUiSelection, setMfaUiSelection] = useState<MfaDestination>(MfaDestinations.email);

  const [MainPage, InfoPage] = match({ authStage, notFound })
    .with({ notFound: true }, () => [
      <LoginPages.NotFound.Main key="notFoundMain" />,
      <LoginPages.NotFound.Info key="notFoundInfo" />
    ])
    .with(
      { authStage: P.union('initial', 'tenant_id', 'insecure', 'refresh_token') },
      { authStage: P.union('complete_auto', 'complete_insecure', 'complete_refresh_token') },
      () => [
        <LoginPages.Hello.Main sso={Boolean(config.SSO_URL)} key="helloMain" />,
        <LoginPages.Hello.Info key="helloInfo" />
      ]
    )
    .with({ authStage: P.union('mfa_select', 'mfa_selected') }, () => [
      <LoginPages.SelectMFA.Main
        // @ts-expect-error this can never be null when in mfa_select, but I don't have the energy to fix the typing right now
        mfaDestinations={mfaDestinations}
        mfaUiSelection={mfaUiSelection}
        setMfaUiSelection={setMfaUiSelection}
        selectMfaDestination={selectMfaDestination}
        openInfo={() => setOpen(true)}
        restartAuthFlow={restartAuthFlow}
        key="selectMfaMain"
      />,
      <LoginPages.SelectMFA.Info key="selectMfaInfo" mfaUiSelection={mfaUiSelection} />
    ])
    .with({ authStage: P.union('mfa_entry', 'mfa_entered') }, { authStage: 'complete_mfa' }, () => [
      <LoginPages.EnterMFA.Main
        invalidCode={invalidCode}
        mfaSelection={mfaSelection}
        // @ts-expect-error this can never be null when in mfa_entry, but I don't have the energy to fix the typing right now
        mfaDestinations={mfaDestinations}
        provideMfaCode={provideMfaCode}
        openInfo={() => setOpen(true)}
        resendCode={resendCode}
        restartAuthFlow={restartAuthFlow}
        key="selectMfaMain"
      />,
      <LoginPages.EnterMFA.Info key="selectMfaInfo" mfaUiSelection={mfaUiSelection} />
    ])
    .exhaustive();

  const showClearSessionButton = ['mfa_select', 'mfa_selected', 'mfa_entry', 'mfa_entered', 'complete_mfa'].includes(
    authStage
  );

  return (
    <>
      <div className={cx(styles['login'], { [styles['login--softphone']]: isSoftphone })}>
        <div className={cx(styles['login__main'])}>
          <div className={styles['login__main__logo']}>
            <ResponsiveLogo isSoftphone={isSoftphone} />
          </div>
          <div
            className={cx(styles['login__main__container'], {
              [styles['login__main__container--softphone']]: isSoftphone
            })}
          >
            {MainPage}
          </div>
          <div className="next-to-30 flex-column">
            {showClearSessionButton && <ClearSessionSignOutButton />}
            <SupportFooter />
          </div>
        </div>
        {match({ isSoftphone, isMediumScreen })
          .with({ isSoftphone: false, isMediumScreen: false }, () => (
            <div className={styles['login__info']}>{InfoPage}</div>
          ))
          .with({ isSoftphone: true }, () => (
            <Drawer
              title="How It Works"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              {InfoPage}
            </Drawer>
          ))
          .with({ isMediumScreen: true }, () => (
            <Modal
              title="How It Works"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              onSave={() => {
                setOpen(false);
              }}
              primaryButtonText="Close"
              hideSecondaryButton
            >
              {InfoPage}
            </Modal>
          ))
          .exhaustive()}
      </div>
      <ToastContainer isSoftphone={isSoftphone} noSidebar={true} />
    </>
  );
}
