import { useEffect, useState } from 'react';

const ONE_SECONDS_MS = 1000;

export const useCountdownTimer = (countdownSecs = 30) => {
  const [secs, setSecs] = useState(countdownSecs);
  const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timer | undefined>(undefined);

  const start = () => {
    const interval = setInterval(() => {
      setSecs((secs) => {
        if (secs === 0) {
          clearInterval(interval);
          return 0;
        }

        return secs - 1;
      });
    }, ONE_SECONDS_MS);

    setCountdownInterval(interval);
  };

  const reset = () => {
    clearInterval(countdownInterval);
    setSecs(countdownSecs);
  };

  const restart = () => {
    reset();
    start();
  };

  useEffect(() => () => clearInterval(countdownInterval), []);

  return { value: secs, reset, start, restart };
};
