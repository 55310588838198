import { PostContactSummaryFailureCode, PostContactSummaryStatus } from '@aws-sdk/client-connect-contact-lens';

import { PostCallAnalysisAction } from '@cloud-wave/neon-common-lib';

import { IFetch } from 'lib/core/context/AuthProvider/Context';

import { TConfig } from 'lib/common/types/Config';
import TTask from 'lib/common/types/Task';
import isVoice from 'lib/common/utils/isVoice';
import toast from 'lib/common/utils/toast';

import getPostCallAnalysis from '../api/getPostCallAnalysis';

export const generateCallSummary = async ({
  selectedTask,
  fetch_,
  config
}: {
  selectedTask: TTask;
  fetch_: IFetch;
  config: TConfig;
}) => {
  if (!isVoice(selectedTask)) {
    return '';
  }

  const postCallAnalysis = await getPostCallAnalysis({
    config,
    fetch_,
    contactId: selectedTask?.taskId,
    action: PostCallAnalysisAction.SUMMARY
  });

  if (!('summary' in postCallAnalysis) || !postCallAnalysis?.summary) {
    toast(
      'info',
      'Your voice interaction is still being analysed. Please try generating notes again in a few moments.'
    );
    return '';
  }

  const { Status, FailureCode, Content } = postCallAnalysis.summary;
  const hasApiFailed = Status === PostContactSummaryStatus.FAILED;
  const hasInsufficientContent = FailureCode === PostContactSummaryFailureCode.INSUFFICIENT_CONVERSATION_CONTENT;

  if (hasApiFailed && hasInsufficientContent) {
    toast('info', 'There isn’t enough content in this voice interaction to generate notes.');
    return '';
  }

  if (hasApiFailed) {
    toast('info', 'Summary generation for this interaction is currently unavailable.');
    return '';
  }

  return Content || '';
};
