import { useConfigContext } from 'lib/core/config';

import Button from 'lib/common/components/Button';

import { useForgetDevice } from '../../../hooks/useForgetDevice';
import handleConnectSignOut from '../../../utils/handleConnectSignOut';

export default function ClearSessionSignOutButton() {
  const { config } = useConfigContext();
  const { forgetDevice } = useForgetDevice();

  return (
    <Button
      styleType="SECONDARY"
      asyncAction
      onClick={async () => {
        try {
          await handleConnectSignOut({ config });
        } catch {}

        try {
          await forgetDevice();
        } catch {}

        window.location.reload();
      }}
    >
      Sign Out
    </Button>
  );
}
