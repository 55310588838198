import pluralise from 'pluralize';

export default function formatDuration(ms) {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const displaySeconds = seconds % 60;
  const displayMinutes = minutes % 60;
  const displayHours = hours % 24;

  return (
    <>
      {days > 0 ? (
        <>
          {days}d<span className="sr-only">{days > 1 ? 'ays' : 'ay'}</span>{' '}
        </>
      ) : (
        ''
      )}
      {displayHours > 0 ? (
        <>
          {displayHours}h<span className="sr-only">{pluralise('our', displayHours)}</span>{' '}
        </>
      ) : (
        ''
      )}
      {displayMinutes > 0 ? (
        <>
          {displayMinutes}m<span className="sr-only">{pluralise('inute', displayMinutes)}</span>{' '}
        </>
      ) : (
        ''
      )}
      {displaySeconds > 0 || ms === 0 ? (
        <>
          {displaySeconds}s<span className="sr-only">{pluralise('econd', displaySeconds)}</span>
        </>
      ) : (
        ''
      )}
    </>
  );
}
