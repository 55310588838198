import { faCalendarClock, faCheck, faForwardFast } from '@fortawesome/pro-regular-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useWrapUpCodeContext } from 'lib/common/contexts/WrapUpCodeContext';

import Button from 'lib/common/components/Button';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import CONNECTION_TYPE from 'lib/common/constants/connectionType';

import { useConfigContext } from 'lib/common/../core/config';
import { useAuthContext } from 'lib/common/../core/context/AuthProvider';
import { ConnectPermissions } from 'lib/common/types/Permissions';
import redactTask from 'lib/common/utils/redactTask';

import TaskFooter from '../TaskFooter';
import TaskFooterRow from '../TaskFooterRow';
import isSaveButtonDisabled from './utils/isSaveButtonDisabled';
import shouldShowSkipButton from './utils/shouldShowSkipButton';

type TFooter = {
  scheduleCallback: () => void;
  children?: React.ReactNode;
};

export default function ACW({ scheduleCallback, children }: TFooter) {
  const {
    state: { tasks, selectedTaskId },
    actions: { removeTask }
  } = useContactContext();
  const {
    state: { queueWrapUpCodes }
  } = useWrapUpCodeContext();

  const { hasPermission } = usePermissionsContext();
  const { config } = useConfigContext();
  const { fetch_ } = useAuthContext();

  const tenantId = config.TENANT_ID;
  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  const { wrapUpCodeName, notes } = selectedTask?.ACW || {};

  const isCreateTaskDisabled = hasPermission({ type: 'connect', permission: ConnectPermissions.CREATE_TASK_DISABLED });

  const ACWIsSkippable = !hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW_CANT_SKIP
  });

  const hasAgentOutcomesPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_INTERACTION_OUTCOMES
  });

  const notesAreRequired = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW_NOTES_REQUIRED
  });

  const taskIsMonitoring = selectedTask?.connectionType === CONNECTION_TYPE.MONITORING;

  // https://cloud-wave.atlassian.net/wiki/spaces/PROD/pages/1439956993/After+Contact+Work#Scenarios
  const showSkipButton = shouldShowSkipButton({
    ACWIsSkippable,
    hasAgentOutcomesPermission,
    taskIsMonitoring
  });
  const saveButtonDisabled = isSaveButtonDisabled({
    wrapUpCodeName,
    notes,
    ACWIsSkippable,
    hasAgentOutcomesPermission,
    taskIsMonitoring,
    queueWrapUpCodes,
    notesAreRequired
  });

  const clearTask = () => {
    if (!selectedTask) {
      return Promise.resolve();
    }

    return removeTask(selectedTask.taskId);
  };

  const onSave = async () => {
    if (!selectedTask || !selectedTask?.contact) {
      return Promise.resolve();
    }

    const outcomePayload = {
      tenantId,
      contactId: selectedTask.contact.contactId,
      calledAt: selectedTask.connectedAtTime?.toISOString() || new Date().toISOString(),
      codeName: selectedTask.ACW?.wrapUpCodeName?.name,
      codeFolder: selectedTask.ACW?.wrapUpCodeName?.pathname,
      notes: selectedTask.ACW?.notes
    };

    try {
      const res = await fetch_(`${config.CALL_SERVICE_URL}/user/interactions?tenantId=${tenantId}`, {
        method: 'POST',
        body: JSON.stringify(outcomePayload)
      });

      logger.info(LogEvents.AFTER_CALL_WORK.SUBMITTED.SUCCESS, { task: redactTask(selectedTask) });

      return res;
    } catch (error: any) {
      logger.error(LogEvents.AFTER_CALL_WORK.SUBMITTED.FAIL, { task: redactTask(selectedTask), error });
    }
  };

  const saveProps = () => {
    if (taskIsMonitoring || !hasAgentOutcomesPermission) {
      return {
        onClick: clearTask
      };
    }

    return {
      onClick: onSave,
      onSuccess: clearTask,
      disabled: saveButtonDisabled
    };
  };

  return (
    <TaskFooter>
      <TaskFooterRow isSingleRow>
        {children}
        {!isCreateTaskDisabled && (
          <Button
            data-testid="after-contact-work-create-task-btn"
            icon={faCalendarClock}
            tooltip="Create a callback agent task"
            onClick={scheduleCallback}
            styleType="NEUTRAL"
            round
            size="small"
          />
        )}
        <Button
          size="medium"
          data-testid="after-contact-work-save-btn"
          icon={faCheck}
          ariaLabel="Complete task"
          tooltip="Complete"
          asyncAction
          round
          successTimeoutSeconds={0}
          preventDoubleClick
          {...saveProps()}
        />
        {showSkipButton && (
          <Button
            ariaLabel="Skip task"
            data-testid="after-contact-work-skip-btn"
            icon={faForwardFast}
            tooltip="Skip"
            styleType="NEUTRAL"
            onClick={async () => {
              try {
                await clearTask();

                logger.info(LogEvents.AFTER_CALL_WORK.SKIPPED, { task: redactTask(selectedTask) });
              } catch (e) {
                console.error(e);

                throw e;
              }
            }}
            round
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
            size="small"
          />
        )}
      </TaskFooterRow>
    </TaskFooter>
  );
}
