import { ReactNode, createContext, useContext } from 'react';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export interface ISoftphoneModalOptionsProps {
  title?: string;
  subtitle?: string;
  content: ReactNode;
  onSave?: () => void;
  onDelete?: () => void;
  onSecondary?: () => void;
  saveDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  deleteButtonText?: string;
  secondaryButtonIcon?: IconDefinition;
  secondaryAsyncAction?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  id?: string;
  onCancel?: () => void;
}

interface ISoftphoneModalContext {
  state: {
    isModalOpen: boolean;
  };
  actions: {
    openModal: (options?: ISoftphoneModalOptionsProps) => void;
    closeModal: () => void;
    updateModalOptions: (value) => void;
  };
}

const Context = createContext<ISoftphoneModalContext>({
  state: {
    isModalOpen: false
  },
  actions: {
    openModal: () => void 0,
    closeModal: () => void 0,
    updateModalOptions: () => void 0
  }
});

export default Context;

// export the context hook
export const useSoftphoneModalContext = () => useContext(Context);
