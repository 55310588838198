import { format } from 'date-fns';
import { Email } from 'postal-mime';

import { AgentEmailSignatureInsertOn, TAgentEmailSignatureInsertOn } from '@cloud-wave/neon-common-lib/common';

import { TAttachment } from 'lib/common/types/email/TAttachment';

import replaceImageTextWithImages from './replaceImageTextWithImages';

const P_SPACER_HTML = '<p></p>';
const HR_HTML_EL = '<hr />';

//Currently the style tag is removed by lexical but if we can keep it, this will solve the large spacing issue
//may be an issue with both lexical and our serverside lib
const wrapElement = (content?: string) =>
  `<p style='margin: 0;' class='editor-paragraph ltr'>${content || '<br/>'}</p>`;

const buildBodyContent = (attachments: TAttachment[], htmlContent?: string, textContent?: string) => {
  const withParagraphBlocks = textContent?.split('\n\n')?.map(wrapElement).join('').replaceAll('\n', '<p/>');

  return replaceImageTextWithImages(attachments, htmlContent, withParagraphBlocks);
};

const buildReplyHeaderHtmlContent = ({
  content,
  emailSubject,
  emailAddress,
  ccEmailRecipientsArray,
  allEmailRecipientsArray
}: any) =>
  `<p><strong>From:</strong> ${emailAddress}</p>
<p><strong>Sent:</strong> ${format(new Date(content.date), 'EEEE, MMMM d, yyyy hh:mm:ss aa')}</p>
<p><strong>To:</strong> ${[...new Set(allEmailRecipientsArray)].join('; ')}</p>
${ccEmailRecipientsArray.length ? `<p><strong>Cc:</strong> ${ccEmailRecipientsArray.join('; ')}</p>` : ''}
<p><strong>Subject:</strong> ${emailSubject}</p>`;

export default function buildReplyForwardHtml({
  content,
  emailSubject,
  emailAddress,
  allEmailRecipientsArray,
  ccEmailRecipientsArray,
  emailSignature,
  emailSignatureInsetOn = AgentEmailSignatureInsertOn.NEVER,
  attachments
}: {
  content: Email;
  emailSubject: string;
  emailAddress: string;
  ccEmailRecipientsArray: string[];
  allEmailRecipientsArray: string[];
  emailSignature?: string;
  emailSignatureInsetOn?: TAgentEmailSignatureInsertOn;
  attachments: TAttachment[];
}) {
  const shouldAddSignature =
    emailSignatureInsetOn === AgentEmailSignatureInsertOn.All ||
    emailSignatureInsetOn === AgentEmailSignatureInsertOn.REPLY;

  const bodyHtml = buildBodyContent(attachments, content?.html, content?.text);
  const signatureHtml = shouldAddSignature ? `${P_SPACER_HTML}${P_SPACER_HTML}${emailSignature}` : '';
  const headerHtml = buildReplyHeaderHtmlContent({
    content,
    emailSubject,
    emailAddress,
    ccEmailRecipientsArray,
    allEmailRecipientsArray
  });

  return `${signatureHtml}${HR_HTML_EL}${headerHtml}${P_SPACER_HTML}${bodyHtml}`;
}
