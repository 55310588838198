import { saveAs } from 'file-saver';

import { TAttachment } from 'lib/common/types/email/TAttachment';

export default function downloadAttachment({ content, filename, mimeType }: TAttachment) {
  if (!content) {
    return;
  }

  saveAs(new Blob([content], { type: mimeType }), filename);
}
