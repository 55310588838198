import { ReactComponent as TeamsLogo } from 'assets/vectors/teams.svg';
import cx from 'classnames';
import _startCase from 'lodash.startcase';

import { ContactType, TAgentContact, TContact } from '@cloud-wave/neon-common-lib';
import { translate } from '@cloud-wave/neon-common-lib/app';

import Icon from 'lib/common/components/Icon';

import { ICON_MAP } from '../../../../constants/numberTypes';

import styles from './contact-directory-icon.module.scss';

export default function ContactDirectoryIcon({
  contact,
  isSoftphone,
  isTeamsContact
}: {
  contact: TContact | TAgentContact;
  isSoftphone?: boolean;
  isTeamsContact: boolean;
}) {
  if (!isTeamsContact) {
    const tooltip = contact.type === ContactType.ORGANISATION ? translate('Organisation').toUpperCase() : contact.type;
    return (
      <Icon
        title={_startCase(tooltip)}
        icon={ICON_MAP[contact.type]}
        size={isSoftphone ? 12 : 13}
        color={'darkGrey'}
        tooltipPlacement="top"
        tooltipArrow
      />
    );
  }

  return (
    <span
      className={cx(styles['contact-directory-icon__icon'], {
        [styles['contact-directory-icon__icon--softphone']]: isSoftphone
      })}
    >
      <TeamsLogo title="Microsoft Teams" />
    </span>
  );
}
