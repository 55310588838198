import {
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFileZip
} from '@fortawesome/pro-regular-svg-icons';

const ICON_MAP = {
  '.document': faFileWord,
  '.presentation': faFilePowerpoint,
  '.spreadsheet': faFileExcel,
  video: faFileVideo,
  pdf: faFilePdf,
  image: faFileImage,
  zip: faFileZip
};

export default function getAttachmentIcon(type: string) {
  const fileIconKey = Object.keys(ICON_MAP).find((iconType) => type.includes(iconType));
  return fileIconKey ? ICON_MAP[fileIconKey] : faFile;
}
