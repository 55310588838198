import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { faCopy, faFileLines } from '@fortawesome/pro-regular-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';

import useVoiceTranscripts from 'lib/common/hooks/useVoiceTranscripts';

import Button from 'lib/common/components/Button';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import ACWSummary from 'lib/common/components/molecules/ACW/components/ACWSummary';
import ChatMessages from 'lib/common/components/molecules/ChatMessages';
import SoftphoneCreateAgentTask from 'lib/common/components/molecules/SoftphoneCreateAgentTask';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import VoiceTranscripts from 'lib/common/components/molecules/VoiceTranscripts';

import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TChatTask from 'lib/common/types/ChatTask';
import TTask from 'lib/common/types/Task';
import { copyMessages } from 'lib/common/utils/ACW/copyMessages';
import { getTaskTimeInfo } from 'lib/common/utils/ACW/getTaskTimeInfo';
import getScheduleCallbackDescription from 'lib/common/utils/getScheduleCallbackDescription';
import getUserName from 'lib/common/utils/getUserName';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import isVoice from 'lib/common/utils/isVoice';

import AttachedData from '../AttachedData';
import InfoPanel from '../InfoPanel';

const AfterContactWork = (props: TTask & { user: any }) => {
  const { profile, queueName, time, connectionValue, contact, connectedAtTime, type, user, taskId } = props;
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const {
    actions: { openModal, closeModal, updateModalOptions }
  } = useSoftphoneModalContext();
  const { hasPermission } = usePermissionsContext();
  const {
    state: { preferredDateFormat }
  } = useAgentPreferencesContext();
  const canViewPostCallTranscripts =
    hasPermission({
      type: 'tenant',
      permission: Permissions.AGENT_INTERACTION_VOICE_ANALYSIS
    }) && isVoice({ type });
  const { transcripts, loading, getCallTranscripts, nextToken } = useVoiceTranscripts({
    config,
    fetch_,
    contactId: taskId,
    canViewPostCallTranscripts
  });

  useEffect(() => {
    updateModalOptions({
      id: 'interaction-transcript',
      content: (
        <VoiceTranscripts
          transcripts={transcripts}
          loading={loading}
          nextToken={nextToken}
          onLoadMore={getCallTranscripts}
        />
      ),
      onSecondary: () =>
        copyMessages({
          type,
          messages: type === CONTACT_TYPES.CHAT ? (props as unknown as TChatTask).messages : transcripts,
          preferredDateFormat
        })
    });
  }, [transcripts, loading, nextToken]);

  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const taskTimeInfo = getTaskTimeInfo({ contact, connectedAtTime });

  const canViewTranscript = type === CONTACT_TYPES.CHAT || canViewPostCallTranscripts;

  const handleOpenHistoryModal = () => {
    openModal({
      title: type === CONTACT_TYPES.CHAT ? 'Chat History' : 'Interaction Transcript',
      id: type === CONTACT_TYPES.CHAT ? 'chat-history' : 'interaction-transcript',
      content:
        type === CONTACT_TYPES.CHAT ? (
          <ChatMessages
            showEmptyPlaceholder
            messages={(props as unknown as TChatTask).messages}
            customerName={name}
            nextToken={(props as unknown as TChatTask).nextToken}
            status={props.status}
          />
        ) : (
          <VoiceTranscripts
            transcripts={transcripts}
            loading={loading}
            nextToken={nextToken}
            onLoadMore={getCallTranscripts}
          />
        ),
      secondaryButtonText: 'Copy',
      secondaryButtonIcon: faCopy,
      secondaryAsyncAction: true,
      onSecondary: () =>
        copyMessages({
          type,
          messages: type === CONTACT_TYPES.CHAT ? (props as unknown as TChatTask).messages : transcripts,
          preferredDateFormat
        })
    });
  };

  const scheduleCallback = () => {
    openModal({
      title: 'Add A New Agent Task',
      content: (
        <SoftphoneCreateAgentTask
          name="Call A Customer Back"
          description={
            type === CONTACT_TYPES.TASK
              ? ''
              : getScheduleCallbackDescription(connectionValue, profile, taskTimeInfo.startedAt, user)
          }
          onClose={closeModal}
        />
      )
    });
  };

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.AFTER_CALL_SCHEDULE_CALLBACK, scheduleCallback, []);

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row text={name} testId={name} />
      </InfoPanel>
      <ACWSummary accordionDefaultOpen taskTimeInfo={taskTimeInfo} />
      <ACWOutcome accordionDefaultOpen />
      <AttachedData task={props} />
      <TaskFooter.ACW scheduleCallback={scheduleCallback}>
        {canViewTranscript && (
          <Button
            data-testid={`after-contact-work-${type === CONTACT_TYPES.CHAT ? 'chat' : 'voice'}-history-btn`}
            icon={faFileLines}
            tooltip={type === CONTACT_TYPES.CHAT ? 'View chat history' : 'View interaction transcript'}
            onClick={handleOpenHistoryModal}
            styleType="NEUTRAL"
            round
            size="small"
          />
        )}
      </TaskFooter.ACW>
    </>
  );
};

export default AfterContactWork;
