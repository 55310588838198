import { ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { Letter } from 'react-letter';

import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons';
import { faDownload, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { filesize } from 'filesize';

import Button from 'lib/common/components/Button';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Loader from 'lib/common/components/Loader';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import PORTAL_IDS from 'lib/common/constants/portalIds';

import IEmailMetadata from 'lib/common/types/email/EmailMetadata';
import { TAttachment } from 'lib/common/types/email/TAttachment';
import { TEmail } from 'lib/common/types/email/TEmail';
import getAttachmentCid from 'lib/common/utils/email/getAttachmentCid';
import getAttachmentsTotalSize from 'lib/common/utils/email/getAttachmentsTotalSize';
import isInlineImage from 'lib/common/utils/email/isInlineImage';

import { LetterHead } from './LetterHead';
import AttachmentPopoverMenuItem from './components/AttachmentPopoverMenuItem';
import downloadAttachment from './utils/downloadAttachment';

import './email.scss';

const CID_URL_PREFIX = 'cid:';

function getContent({
  error,
  loading,
  content,
  isSoftphone
}: {
  error: boolean;
  loading: boolean;
  content?: TEmail | null;
  isSoftphone?: boolean;
}) {
  if (error) {
    return (
      <EmptyPlaceholder
        className={isSoftphone ? 'mb-10' : ''}
        isError
        icon={faEnvelope}
        text={
          !isSoftphone
            ? 'There was a problem loading the content of this email. Please try again.'
            : "We couldn't load the email content."
        }
      />
    );
  }

  if (loading) {
    return <Loader relative small={!isSoftphone} className="email__loader" size={isSoftphone ? 30 : void 0} />;
  }

  const rewriteSrc = (url: string) => {
    if (!url.startsWith(CID_URL_PREFIX)) {
      return url;
    }

    const attachmentId = url.split(CID_URL_PREFIX)[1];
    const inlineAttachment = content?.attachments?.find((attachment) => getAttachmentCid(attachment) === attachmentId);

    if (!inlineAttachment) {
      return url;
    }

    return `data:${inlineAttachment.mimeType};base64,${inlineAttachment.base64}`;
  };

  return (
    <Letter
      html={content?.html || ''}
      text={content?.text}
      className={cx('email__content', { 'email__content--softphone': isSoftphone })}
      rewriteExternalResources={rewriteSrc}
    />
  );
}

export default function Email({
  content,
  metadata,
  loading,
  error,
  attachments,
  isSoftphone,
  showAllFields,
  neonEmailCcRecipients,
  neonEmailAllRecipients,
  hideDate
}: {
  content?: TEmail | null;
  metadata?: IEmailMetadata;
  loading: boolean;
  error: boolean;
  attachments: TAttachment[];
  isSoftphone?: boolean;
  showAllFields?: boolean;
  hideDate?: boolean;
  neonEmailCcRecipients: string;
  neonEmailAllRecipients: string;
}) {
  const externalAttachments = attachments.filter((attachment) => !isInlineImage(attachment));
  const inlineImageAttachments = attachments.filter(isInlineImage);

  const downloadAllAttachments = () => {
    externalAttachments.forEach(downloadAttachment);
  };

  const AttachmentsEl = () => {
    if (!attachments.length) {
      return null;
    }

    return (
      <PopoverMenu
        anchor={
          <Button
            className="no-shrink"
            icon={faPaperclip}
            size="small"
            styleType="SECONDARY"
            data-testid="email-attachments"
          >
            {attachments.length} Attachment{attachments.length === 1 ? '' : 's'}
          </Button>
        }
      >
        {
          externalAttachments.map((attachment: TAttachment) => (
            <AttachmentPopoverMenuItem key={attachment.hash} attachment={attachment} />
          )) as unknown as ReactElement
        }
        {externalAttachments.length && inlineImageAttachments.length ? <PopoverMenuItem.Separator /> : void 0}
        {inlineImageAttachments.length ? (
          <PopoverMenuItem.Header
            text="Inline Images"
            subtitle="The original full-size images displayed inside this email."
          />
        ) : (
          void 0
        )}
        {
          inlineImageAttachments.map((attachment: TAttachment) => (
            <AttachmentPopoverMenuItem key={attachment.hash} attachment={attachment} />
          )) as unknown as ReactElement
        }
        {attachments.length > 1 ? <PopoverMenuItem.Separator /> : void 0}
        {attachments.length > 1 ? (
          <PopoverMenuItem
            key="download-all"
            icon={faDownload}
            text="Download All"
            onClick={downloadAllAttachments}
            subtitle={`(${filesize(getAttachmentsTotalSize(attachments), { round: 0 })})`}
            highlighted
          />
        ) : (
          void 0
        )}
      </PopoverMenu>
    );
  };

  // Softphone attachments are rendered in a different place in the DOM tree, so we need to use createPortal to render them in Info Panel
  const softphoneAttachments =
    document.getElementById(PORTAL_IDS.SOFTPHONE_ATTACHMENT_PORTAL_ID) &&
    createPortal(<AttachmentsEl />, document.getElementById(PORTAL_IDS.SOFTPHONE_ATTACHMENT_PORTAL_ID) as Element);

  const Letterhead = LetterHead({
    content,
    neonEmailAllRecipients,
    neonEmailCcRecipients,
    metadata,
    showAllFields,
    isSoftphone,
    attachments,
    AttachmentsEl,
    softphoneAttachments,
    hideDate,
    loading
  });

  return (
    <div className="email">
      {Letterhead}
      {getContent({ error, isSoftphone, content, loading })}
    </div>
  );
}
