import { useState } from 'react';

import { Auth } from 'aws-amplify';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

export const useAuthSession = () => {
  const [signedOut, setSignedOut] = useState<boolean>(false);

  const fetch_ = async (url: string, options: RequestInit = {}): Promise<Response> => {
    const currentSession = await Auth.currentSession().catch((error) => {
      logger.warn(LogEvents.AUTH.REFRESH_TOKEN_FAIL, { error });
      setSignedOut(true);
    });
    const token = currentSession?.getIdToken().getJwtToken();

    if (!token) {
      logger.warn(LogEvents.AUTH.REFRESH_TOKEN_FAIL);
      console.log('HELLO HERE 2', url);
      setSignedOut(true);
      throw 'Unauthorized';
    }

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    const result = await fetch(url, { ...options, headers });

    if (!result.ok) {
      const errorBody = await result?.json?.();

      throw { ...errorBody, status: result.status };
    }

    return result;
  };

  return { fetch_, signedOut, setSignedOut };
};
