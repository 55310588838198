import { useRef, useState } from 'react';

import { Auth } from 'aws-amplify';

import { useConfigContext } from 'lib/core/config';
import { ValueOf } from 'lib/core/types';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import SIGN_OUT_TYPES from '../constants/signOutTypes';
import { AuthStages, AuthState } from '../types/AuthState';
import getNormalisedUsername from '../utils/getNormalisedUsername';
import handleConnectSignOut from '../utils/handleConnectSignOut';
import shouldRefreshToken from '../utils/shouldRefreshToken';
import { useHandleAuthStep } from './useHandleAuthStep';
import { useMfaFlow } from './useMfaFlow';

type Fetch = (url: string, options?: RequestInit) => Promise<Response>;

export const useAuthFlow = ({
  setError,
  fetch_,
  email,
  setSignedOut
}: {
  setError: (error: boolean) => void;
  fetch_: Fetch;
  email: string;
  setSignedOut: (signedOut: boolean) => void;
}) => {
  const { config } = useConfigContext();

  const [authState, setAuthState] = useState<AuthState>({ stage: AuthStages.initial });
  const [invalidCode, setInvalidCode] = useState(false);
  const [signingOut, setSigningOut] = useState<boolean>(false);

  const signingOutRef = useRef<null | ValueOf<typeof SIGN_OUT_TYPES>>(null);

  const { selectMfaDestination, mfaDestinations, setMfaDestinations, provideMfaCode, mfaSelection } = useMfaFlow({
    setInvalidCode,
    setAuthState,
    setError,
    authState
  });

  const { handleAuthStep } = useHandleAuthStep({ setMfaDestinations, setInvalidCode, setError });

  const beginAuth = async (existingConnectUsername?: string) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      const userMatchesStorage =
        existingConnectUsername &&
        currentUser.attributes.email !==
          getNormalisedUsername({ tenantId: config.TENANT_ID, username: existingConnectUsername });

      if (userMatchesStorage) {
        throw new Error('User is not the same as the one logged in');
      }

      if (currentUser && !shouldRefreshToken()) {
        handleAuthStep({ stage: AuthStages.complete_auto });
        setAuthState({ stage: AuthStages.complete_auto });
        return;
      }
    } catch {}

    const newState = await handleAuthStep({ stage: AuthStages.initial });
    setAuthState(newState);
  };

  const signIn = async (existingConnectUsername?: string) => {
    try {
      await beginAuth(existingConnectUsername);

      logger.info(LogEvents.AUTH.SIGN_IN.SUCCESS, { username: '' });
    } catch (error) {
      logger.info(LogEvents.AUTH.SIGN_IN.FAIL, { username: '', error });
      setError(true);
    }
  };

  const signOut = async (type = SIGN_OUT_TYPES.MANUAL_SIGN_OUT) => {
    if (signingOutRef?.current) {
      return;
    }

    logger.info(LogEvents.AUTH.SIGN_OUT.INITIATED, { user: email });

    signingOutRef.current = type;
    setSigningOut(true);

    handleConnectSignOut({ config });

    fetch_(`${config.AGENT_SERVICE_URL}/agent/${config.TENANT_ID}__${sessionStorage.getItem('c_user')}/cleanup/`, {
      body: 'close',
      method: 'POST'
    });

    logger.info(LogEvents.AUTH.SIGN_OUT.COMPLETED.SUCCESS, { user: email });

    setSigningOut(false);

    // Don't reload the page if it's a manual sign out
    if (type === SIGN_OUT_TYPES.MANUAL_SIGN_OUT) {
      return void setSignedOut(true);
    }

    window.location.href = window.location.origin;
  };
  //Cheating going to select screen as state changing isn't working as expected
  const resendCode = async () => {
    if (authState.stage !== AuthStages.mfa_entry) {
      return;
    }
    const mfaSelection = authState.mfaSelection;
    const newState = await handleAuthStep({ stage: AuthStages.initial });

    await selectMfaDestination(mfaSelection, newState);
  };

  //Cheating going to select screen as state changing isn't working as expected
  const restartAuthFlow = async () => {
    const newState = await handleAuthStep({ stage: AuthStages.initial });
    setAuthState(newState);
  };
  return {
    signIn,
    signOut,
    signingOutRef,
    signingOut,
    invalidCode,
    authStage: authState.stage,

    // MFA related auth flow
    selectMfaDestination,
    provideMfaCode,
    mfaDestinations,
    mfaSelection,
    resendCode,
    restartAuthFlow
  };
};
