import cx from 'classnames';

import Text from 'lib/common/components/Text';

import styles from './header.module.scss';

export default function Header({ text, subtitle }: { text: string; subtitle?: string }) {
  return (
    <div className={cx(styles.header, { [styles['header--with-subtitle']]: subtitle })}>
      <Text bold>{text}</Text>
      {subtitle && (
        <Text type="small" color="darkGrey" className={styles['header__subtitle']}>
          {subtitle}
        </Text>
      )}
    </div>
  );
}
