import { format } from 'date-fns';
import _capitalize from 'lodash.capitalize';

import formatTranscriptTime from 'lib/common/components/molecules/VoiceTranscripts/utils/formatTranscriptTime';

import isVoice from '../isVoice';

export function copyMessages({ type, messages, preferredDateFormat }) {
  if (!messages) {
    return;
  }

  if (isVoice({ type })) {
    const formattedMessages = messages
      .map(
        (transcript) =>
          `${_capitalize(transcript.ParticipantRole)} (${formatTranscriptTime(transcript.BeginOffsetMillis)}): ${
            transcript.Content
          }`
      )
      .join('\n\n');
    navigator.clipboard.writeText(formattedMessages);
    return;
  }

  const formattedMessages = messages.reduce((acc, message) => {
    if (message.Type === 'MESSAGE') {
      return `${acc}${_capitalize(message.ParticipantRole)} (${format(
        new Date(message.AbsoluteTime),
        `${preferredDateFormat} - HH:mm:ss`
      )}): ${message.Content}\n\n`;
    }

    return acc;
  }, '');

  navigator.clipboard.writeText(formattedMessages);
  return;
}
