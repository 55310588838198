import { useMemo, useState } from 'react';

import { faCheck, faRotate, faSparkles, faTimes } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import _debounce from 'lodash.debounce';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import { Input } from 'lib/common/components/Input';
import Text from 'lib/common/components/Text';
import CopyToClipboardIcon from 'lib/common/components/atoms/CopyToClipboardIcon';

import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TChatTask from 'lib/common/types/ChatTask';
import TTask from 'lib/common/types/Task';
import TaskContentTypes from 'lib/common/types/TaskContentTypes';
import toast from 'lib/common/utils/toast';

import { generateCallSummary } from '../utils/generateCallSummary';
import { generateChatAISummary } from '../utils/generateChatAISummary';

import styles from './ai-summary-input.module.scss';

const UPDATE_TASK_CONTENT_DEBOUNCE_MS = 500;

export default function AISummaryInput({ selectedTask }: { selectedTask: TTask }) {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const {
    actions: { setTaskContent },
    state: { selectedTaskId }
  } = useContactContext();
  const { isSoftphone } = useLayout();

  const [isCompletingAsyncAction, setIsCompletingAsyncAction] = useState(false);
  const [notes, setNotes] = useState(selectedTask?.ACW?.notes || '');
  const [aiGeneratedSummary, setAiGeneratedSummary] = useState('');

  const onGenerateSummary = async () => {
    try {
      setIsCompletingAsyncAction(true);

      if (selectedTask?.type === CONTACT_TYPES.CHAT) {
        const chatSummary = await generateChatAISummary({ selectedTask: selectedTask as TChatTask, fetch_, config });

        setAiGeneratedSummary(chatSummary);
        setIsCompletingAsyncAction(false);
        return;
      }

      const callSummary = await generateCallSummary({
        selectedTask,
        fetch_,
        config
      });

      setAiGeneratedSummary(callSummary);
      setIsCompletingAsyncAction(false);
    } catch (error) {
      toast('info', 'Summary generation for this interaction is currently unavailable.');
      setIsCompletingAsyncAction(false);
      throw error;
    }
  };

  const handleUpdatedNotes = useMemo(
    () =>
      _debounce((notes: string) => {
        setTaskContent(selectedTaskId, TaskContentTypes.ACW, { ...selectedTask?.ACW, notes: notes || void 0 });
      }, UPDATE_TASK_CONTENT_DEBOUNCE_MS),
    [selectedTask?.ACW]
  );

  const onNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);

    handleUpdatedNotes(event.target.value);
  };

  const handleAcceptAiSummary = () => {
    setNotes(aiGeneratedSummary);
    setTaskContent(selectedTaskId, TaskContentTypes.ACW, { ...selectedTask?.ACW, notes: aiGeneratedSummary });
    setAiGeneratedSummary('');
  };

  return (
    <div className={styles['ai-summary-input']}>
      <Input
        multiline
        minRows={4}
        placeholder="More information about this interaction"
        maxRows={8}
        value={notes}
        onChange={onNotesChange}
        className={cx(styles['ai-summary-input__input'])}
        disabled={isCompletingAsyncAction || Boolean(aiGeneratedSummary)}
      />
      {!aiGeneratedSummary && (
        <Button
          onClick={onGenerateSummary}
          asyncAction
          icon={faSparkles}
          successTimeoutSeconds={0}
          size="small"
          styleType="SECONDARY"
          testId="generate-ai-summary-button"
          className={styles['ai-summary-input__generate-button']}
        >
          Generate Notes
        </Button>
      )}
      {aiGeneratedSummary && (
        <div
          className={cx(styles['ai-summary-input__overlay'], {
            [styles['ai-summary-input__overlay--softphone']]: isSoftphone
          })}
        >
          <Text
            className={cx(styles['ai-summary-input__overlay__summary'], {
              [styles['ai-summary-input__overlay__summary--softphone']]: isSoftphone
            })}
          >
            {aiGeneratedSummary}
          </Text>
          <div className={styles['ai-summary-input__overlay__actions']}>
            <div className={styles['ai-summary-input__overlay__actions__left']}>
              {selectedTask?.type === CONTACT_TYPES.CHAT && (
                <ClickableIcon
                  icon={faRotate}
                  size={17}
                  onClick={onGenerateSummary}
                  disabled={isCompletingAsyncAction}
                  spin={isCompletingAsyncAction}
                />
              )}
              <CopyToClipboardIcon text={aiGeneratedSummary} iconSize={17} />
            </div>
            {!isCompletingAsyncAction && (
              <div className={styles['ai-summary-input__overlay__actions__right']}>
                <Button
                  onClick={() => setAiGeneratedSummary('')}
                  size="small"
                  styleType="SECONDARY"
                  tooltip="Cancel"
                  icon={faTimes}
                />
                <Button
                  onClick={handleAcceptAiSummary}
                  size="small"
                  styleType="PRIMARY"
                  tooltip="Accept Summary"
                  icon={faCheck}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
