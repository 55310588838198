import { faMessageDots } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import Text from 'lib/common/components/Text';

import styles from './voice-transcripts-footer.module.scss';

export default function VoiceTranscriptsFooter({ loading, nextToken, handleLoadMoreTranscripts }) {
  if (!loading && !nextToken) {
    return null;
  }

  return (
    <div className={styles['voice-transcripts-footer']}>
      {loading ? (
        <Loader relative size={35} />
      ) : (
        <>
          <Icon icon={faMessageDots} size={17} className="mb-10" color="darkGrey" />
          <Text onClick={handleLoadMoreTranscripts}>Load More Messages</Text>
        </>
      )}
    </div>
  );
}
