import { LogEvents, logger } from 'lib/common/components/LoggerController';

import { TConfig } from 'lib/common/types/Config';

export default async function handleConnectSignOut({ config }: { config: TConfig }) {
  try {
    await fetch(`${config.CONNECT_HOST}/connect/logout`, {
      credentials: 'include',
      mode: 'no-cors'
    });
  } catch (error) {
    logger.error(LogEvents.AUTH.SIGN_OUT.COMPLETED.FAIL, { error });
    return;
  }

  connect.core.terminate();
}
