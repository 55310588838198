import { useEffect, useState } from 'react';

import { Amplify } from 'aws-amplify';

import { useConfigContext } from 'lib/core/config';

export const useAmplify = () => {
  const { config } = useConfigContext();
  const [isAmplifyConfigured, setIsAmplifyConfigured] = useState<boolean>(false);

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: config.COGNITO_USER_POOL_ARN.split(':')[3],
        userPoolId: config.COGNITO_USER_POOL_ARN.split('/')[1],
        userPoolWebClientId: config.COGNITO_CLIENT_ID
      }
    });

    setIsAmplifyConfigured(true);
  }, []);

  return { isAmplifyConfigured };
};
