import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import { IconDefinition, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Backdrop, Fade, Modal as MUIModal } from '@material-ui/core';
import cx from 'classnames';

import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import Text from 'lib/common/components/Text';

import './modal.scss';

export type TModal = {
  title?: string;
  open?: boolean;
  onSuccess?: (_: any) => void;
  onClose?: () => void;
  onSecondary?: () => void;
  children: ReactNode;
  onSave?: (_: any) => Promise<any> | void;
  onDelete?: (_: unknown) => Promise<unknown> | void;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  hideFooter?: boolean;
  contentTopPadded?: boolean;
  secondaryButtonText?: ReactNode;
  secondaryButtonIcon?: IconDefinition;
  secondaryAsyncAction?: boolean;
  primaryButtonText?: string;
  deleteButtonText?: string;
  hideSecondaryButton?: boolean;
  subtitle?: string;
  syncAction?: boolean;
};

const SIZES = {
  small: 500,
  medium: 600,
  large: 700
};

function getMaxWidth({ small, medium, large }) {
  if (small) {
    return SIZES.small;
  }

  if (medium || (!small && !large)) {
    return SIZES.medium;
  }

  return SIZES.large;
}

export default function Modal({
  title,
  open,
  onSuccess,
  onClose,
  onSecondary,
  children,
  saveDisabled,
  deleteDisabled,
  onSave,
  onDelete,
  small,
  medium,
  large,
  hideFooter,
  contentTopPadded,
  secondaryButtonIcon,
  secondaryAsyncAction,
  secondaryButtonText = 'Cancel',
  primaryButtonText = 'Save',
  deleteButtonText = 'Delete',
  hideSecondaryButton,
  subtitle,
  syncAction
}: TModal) {
  const maxWidth = getMaxWidth({ small, medium, large });
  const fullWidth = useMediaQuery({ query: `(max-width: ${maxWidth + 20}px)` });

  return (
    <MUIModal
      open={Boolean(open)}
      className="cw-modal"
      onClose={onClose}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={Boolean(open)}>
        <div className="cw-modal__children" style={{ maxWidth: fullWidth ? 'calc(100% - 20px)' : maxWidth }}>
          <div className="cw-modal__children__header">
            <div className="cw-modal__children__header__title-bar">
              {title && <Text type="heading1">{title}</Text>}
              <ClickableIcon
                aria-label="Close Modal"
                size={30}
                className="cw-modal__children__header__close"
                icon={faXmark}
                onClick={onClose}
              />
            </div>
            {subtitle && (
              <Text className="mt-10" color="darkGrey">
                {subtitle}
              </Text>
            )}
          </div>

          <div
            className={cx('cw-modal__children__content', {
              'cw-modal__children__content--padded': contentTopPadded
            })}
          >
            {children}
          </div>

          {!hideFooter && (
            <div className="cw-modal__children__footer">
              {!hideSecondaryButton && (
                <Button
                  onClick={onSecondary || onClose}
                  styleType="SECONDARY"
                  icon={secondaryButtonIcon}
                  asyncAction={secondaryAsyncAction}
                >
                  {secondaryButtonText}
                </Button>
              )}
              {onSave && (
                <Button
                  data-testid="modal-save-button"
                  disabled={saveDisabled}
                  onClick={saveDisabled ? void 0 : onSave}
                  onSuccess={onSuccess}
                  asyncAction={!syncAction}
                >
                  {primaryButtonText}
                </Button>
              )}
              {onDelete && (
                <Button
                  data-testid="modal-delete-button"
                  styleType="DANGER"
                  disabled={deleteDisabled}
                  onClick={deleteDisabled ? void 0 : onDelete}
                  onSuccess={onSuccess}
                  asyncAction
                >
                  {deleteButtonText}
                </Button>
              )}
            </div>
          )}
        </div>
      </Fade>
    </MUIModal>
  );
}
